<!--
 * @Author: wxb
 * @Date: 2021-06-21 15:13:39
 * @LastEditTime: 2021-11-03 10:13:51
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Organization\compareCert.vue
-->
<template>
  <div class="containner">
    <div class="compare-container"
         :style="{paddingTop:onfixed?'220px':'0px'}">
      <div class="compare-header"
           :class="{'compare-header-fixed':onfixed}"
           :style="{left:fixedleft}">
        <div class="compare-header-tools">
          <el-checkbox v-model="hideSome">显示不同项</el-checkbox>
          <el-checkbox v-model="showSome">显示相同项</el-checkbox>
          <el-checkbox v-model="hightSome">高亮显示不同项</el-checkbox>
          <div>
            <button type="button"
                    class="screen-info"
                    @click="showDialogCompany">筛选机构</button>
          </div>
        </div>
        <div class="compare-header-item"
             v-for="(item,index) in qualificationList"
             :key="index">
          <div class="delete-item"
               @click="delCompareItem(index)">
            <i class="el-icon-close"></i>
          </div>
          <div class="change-item el-icon-sort"
               @click="changePosition(index)"></div>
          <div class="goodsinfo">
            <div class="product-title"
                 :title="item.strQualifyName">
              {{item.strQualifyName}}
            </div>
            <div class="product-model">
              {{item.levelName}}
            </div>
            <div class="product-tools">
              <button class="collection"
                      type="button"
                      @click="changeQualify(index)"><span v-if="item.strQualifyName==='资质名称'">添加资质</span><span v-else>换资质</span></button>
            </div>
          </div>
        </div>
        <div class="compare-header-add">
          <!-- <button v-if="companyList.length<6"
                    @click="addCompareItem">添加产品</button> -->
          <!-- <button v-show="elements['qualify_compare:btn_add']"
                  @click="addCompareItem">添加资质</button> -->
        </div>
      </div>
      <div class="compare-content">
        <div class="box-content"
             v-for="(item,index) in compareList"
             :key="index"
             v-show="hideSome ? (hideSome ? item.different : true) : (showSome ? item.isSame : true)"
             :class="{high: hightSome && item.different}">
          <div class="box-title">
            <router-link tag="a"
                         target="_blank"
                         class="detail-link"
                         :title="item.mingCh"
                         :to="{name:'orgDetail',params:{id:item.companyID}}">{{item.mingCh}}</router-link>
          </div>
          <div class="box-body"
               v-for="(v,i) in item.comqualifyInfoArr"
               :key="i">
            <span v-if="v.isHave">有</span>
          </div>
          <div class="placeholder"></div>
        </div>
      </div>
      <div class="compare-loadmore"
           v-show="hasMore"
           @click="handleClickLoadMore">加载更多...</div>
    </div>
    <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    <el-dialog title="选择资质"
               v-dialogDrag
               :visible.sync="dialogQualificationVisible"
               :close-on-click-modal="false"
               width="900px">
      <el-form ref="form"
               :model="qualificationFormSearch"
               @submit.native.prevent
               label-width="80px">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="资质分类">
              <el-select v-model="qualificationFormSearch.idsArr"
                         multiple
                         collapse-tags
                         @change="handleEnterKey"
                         clearable
                         style="width:100%;"
                         placeholder="请选择资质分类">
                <el-option v-for="item in qualifyCategoryList"
                           :key="item.value"
                           :label="item.strQualifyName"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="资质名称">
              <el-input v-model="qualificationFormSearch.strQualifyName"
                        placeholder="请输入资质名称"
                        clearable
                        @keyup.enter.native="handleEnterKey"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-button type="primary"
                       @click="handleEnterKey">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table :data="qualificationTableData"
                stripe
                @row-click="qualificationRowClick"
                style="width: 100%">
        <template slot="empty">
          <el-empty description="哎呀，未查询到相关资质信息"></el-empty>
        </template>
        <el-table-column label="资质名称"
                         header-align="center"
                         align="left">
          <template slot-scope="scope">
            <el-radio v-model="qualificationChecked"
                      v-if="scope.row.levelID"
                      :label="`${scope.row.strQualifyCode}@@@${scope.row.levelID}`">{{scope.row.strQualifyName}}</el-radio>
            <el-radio v-model="qualificationChecked"
                      v-else
                      :label="`${scope.row.strQualifyCode}@@@0`">{{scope.row.strQualifyName}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="levelName"
                         label="资质等级"
                         align="center">
        </el-table-column>
        <el-table-column prop="strTopName"
                         align="center"
                         label="资质类别">
        </el-table-column>
      </el-table>
      <div class="company-page">
        <el-pagination background
                       layout="prev, slot, next"
                       :total="qualificationTableCnt"
                       @current-change="handleCurrentChange"
                       :current-page.sync="qualifyCurr"
                       :hide-on-single-page="true">
          <span class="current-page">{{qualifyCurr}}</span>
        </el-pagination>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="danger"
                   @click="dialogQualificationVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="handleClickDetermine">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="筛选机构"
               v-dialogDrag
               :visible.sync="dialogCompanyVisible"
               :close-on-click-modal="false"
               width="650px">
      <div class="tips"><i class="el-icon-info"></i>注：所有筛选信息在资质更换或加载更多后都将失效，需要重新设置</div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="按地区筛选"
                     name="first">
          <el-transfer filter-placeholder="请输入地区名称筛选"
                       filterable
                       v-model="registerAreaSelect"
                       :titles="['注册属地','已选择注册属地']"
                       :data="registerAreaAry">
          </el-transfer>
        </el-tab-pane>
        <el-tab-pane label="按机构筛选"
                     name="second">
          <el-transfer filter-placeholder="请输入机构名称筛选"
                       filterable
                       v-model="orgSelect"
                       :titles="['机构名称','已选机构']"
                       :data="orgLst"
                       :render-content="renderFunc">
          </el-transfer>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="danger"
                   @click="dialogCompanyVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="handleDetermineCompare">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import dialogviporlogin from '@/components/dialogviporlogin'
import { mapGetters } from 'vuex'
import { getInfoTree, getInfoList, compareCompany4Qualify } from '@/api/organization'
const emptyObj = {
  strQualifyName: '资质名称'
}

const QUALIFICATIONLIST = [
  {
    strQualifyName: '资质名称'
  }, {
    strQualifyName: '资质名称'
  }, {
    strQualifyName: '资质名称'
  }, {
    strQualifyName: '资质名称'
  }
]
export default {
  name: 'compareCert',
  data() {
    return {
      qualifyCategoryList: [],
      qualificationList: [],
      completeList: [],
      compareList: [],
      hideSome: false,
      showSome: false,
      hightSome: false,
      onfixed: 0,
      fixedleft: 0,
      dialogQualificationVisible: false,
      qualifyCurr: 1,
      qualificationFormSearch: {
        operateID: 1,
        strQualifyName: '',
        idsArr: [],
        blFinal: 1,
        start: 0,
        limit: 10
      },
      qualificationChecked: -1,
      qualificationTableData: [],
      qualificationTableCnt: 0,
      dialogCompanyVisible: false,
      // 需要替换的资质
      qualifyIndex: 0,
      canSee: true,
      viporlogin: 'login',
      hasMore: false,
      pageNo: 1,
      compareSearch: {
        start: 0,
        limit: 50,
        operateID: 1,
        qualifyBOS: []
      },
      registerArea: new Set(),
      activeName: 'first',
      registerAreaSelect: [],
      registerAreaAry: [],
      orgSelect: [],
      orgLst: []
    }
  },
  components: {
    dialogviporlogin
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    // 处理滚动固定头部
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      const clientWidth = document.body.clientWidth
      const scrollLeft = window.pageXOffset
      let fixedleft = 0
      if (clientWidth > 968) {
        fixedleft = (clientWidth - 968) / 2 - scrollLeft
      } else {
        fixedleft = ~scrollLeft
      }
      this.fixedleft = `${fixedleft}px`

      this.onfixed = scrollTop >= 200
    },
    // 删除对比资质
    delCompareItem(index) {
      // this.qualificationList[index] = emptyObj
      // this.initCompare()
      this.qualificationList.splice(index, 1)
      // 如果小于四个补充
      if (this.qualificationList.length < 4) {
        this.qualificationList.push(...Array.from({ length: 4 - this.qualificationList.length }, () => JSON.parse(JSON.stringify(emptyObj))))
      }
      const newArr = this.qualificationList.filter(item => item.strQualifyID)
      if (newArr.length === 0) {
        // 展示对比列表清空并清空localstroage中的对比资质列表
        this.compareList = []
        sessionStorage.removeItem('certCompareList')
        // 加载更多隐藏
        this.hasMore = false
        // 页码置为1
        this.pageNo = 1
        // 按地区筛选
        this.registerArea = new Set()
        this.registerAreaSelect = []
        this.registerArea = []
        // 重置按机构筛选
        this.orgSelect = []
        this.orgLst = []
        this.$forceUpdate()
      } else {
        this.initCompare()
      }
    },
    // 换位置
    changePosition(index) {
      const idx1 = index
      const idx2 = index + 1
      this.qualificationList.splice(idx2, 1, ...this.qualificationList.splice(idx1, 1, this.qualificationList[idx2]))
      for (const item of this.compareList) {
        item.comqualifyInfoArr.splice(idx2, 1, ...item.comqualifyInfoArr.splice(idx1, 1, item.comqualifyInfoArr[idx2]))
      }
    },
    // 更换资质
    changeQualify(index) {
      if (!this.canSee) {
        this.$refs.dialogviporlogin.handleShow(this.viporlogin)
        return
      }
      this.qualifyIndex = index
      this.qualificationChecked = -1
      this.qualificationFormSearch.start = 0
      this.qualificationFormSearch.strQualifyName = ''
      this.qualificationFormSearch.idsArr = []
      this.qualifyCurr = 1
      this.getInfoList()
      this.dialogQualificationVisible = true
    },
    // 弹出框中资质行点击事件
    qualificationRowClick(row, column, event) {
      this.qualificationChecked = row.levelID ? `${row.strQualifyCode}@@@${row.levelID}` : `${row.strQualifyCode}@@@0`
    },
    // 资质关键词和搜索按钮点击事件
    handleEnterKey() {
      this.qualificationChecked = -1
      this.qualificationFormSearch.start = 0
      this.getInfoList()
    },
    // 资质列表分页处理事件
    handleCurrentChange(val) {
      this.qualificationFormSearch.start = (val - 1) * this.qualificationFormSearch.limit
      this.getInfoList()
    },
    // 获取资质列表
    getInfoList() {
      getInfoList(this.qualificationFormSearch).then(data => {
        if (data.ok) {
          this.qualificationTableCnt = data.cnt
          this.qualificationTableData = data.lst
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    // 确认更换资质事件
    handleClickDetermine() {
      const index = this.qualificationList.findIndex((value, index, arr) => value.strQualifyID === this.qualificationChecked)
      if (index !== -1) {
        this.$message({
          showClose: true,
          message: '该资质已经添加到对比中，无需再次添加',
          type: 'warning'
        })
        return
      }
      const obj = {}
      for (const item of this.qualificationTableData) {
        const strQualifyID = item.levelID ? `${item.strQualifyCode}@@@${item.levelID}` : `${item.strQualifyCode}@@@0`
        if (strQualifyID === this.qualificationChecked) {
          obj.strQualifyName = item.strQualifyName
          obj.levelName = item.levelName
          obj.strQualifyID = strQualifyID
          break
        }
      }
      this.qualificationList[this.qualifyIndex] = obj
      this.initCompare()
      this.dialogQualificationVisible = false
    },
    // 初始化初次对比
    initCompare() {
      // 注册地址初始化状态
      this.registerArea = new Set()
      this.registerAreaAry = []
      this.registerAreaSelect = []
      // 机构筛选列表初始化状态
      this.orgLst = []
      this.orgSelect = []
      // 资质对比相关接口
      this.pageNo = 1
      this.compareSearch.start = 0
      const qualifyBOS = []
      const certCompareList = []
      for (const item of this.qualificationList) {
        if (item.strQualifyID !== undefined) {
          qualifyBOS.push(item.strQualifyID)
          certCompareList.push({
            strQualifyName: item.strQualifyName,
            levelName: item.levelName,
            strQualifyID: item.strQualifyID
          })
        }
      }
      sessionStorage.setItem('certCompareList', JSON.stringify(certCompareList))
      this.compareSearch.qualifyBOS = qualifyBOS
      if (qualifyBOS.length === 0) {
        this.hasMore = false
        return
      }
      compareCompany4Qualify(this.compareSearch).then(data => {
        if (data.ok) {
          const COMPARELIST = data.lst || []
          if (COMPARELIST.length > 0) {
            this.hasMore = true
          } else {
            this.hasMore = false
          }
          for (const item of COMPARELIST) {
            const comqualifyInfoArr = item.comqualifyInfoArr
            this.registerArea.add(item.zhcsd)
            this.orgLst.push({
              key: item.companyID,
              label: item.mingCh
            })
            const diffSet = new Set()
            for (const v of comqualifyInfoArr) {
              if (Object.keys(v).length !== 0) {
                diffSet.add(v.isHave)
              }
            }

            // 根据选择的机构进行排序
            let newComQualifyInfoArr = []
            for (const value of item.comqualifyInfoArr) {
              const strQualifyID = value.dengJ ? `${value.strQualifyCode}@@@${value.dengJ}` : `${value.strQualifyCode}@@@0`
              const index = this.qualificationList.findIndex((cv, index, arr) => cv.strQualifyID === strQualifyID)
              newComQualifyInfoArr[index] = value
            }

            if (newComQualifyInfoArr.length < this.qualificationList.length) {
              const arrLength = this.qualificationList.length - newComQualifyInfoArr.length
              const arr = Array(arrLength).fill({}, 0, arrLength)
              newComQualifyInfoArr = [...newComQualifyInfoArr, ...arr]
            }

            for (let i = 0; i < newComQualifyInfoArr.length; i++) {
              if (!newComQualifyInfoArr[i]) {
                newComQualifyInfoArr[i] = {}
              }
            }

            item.comqualifyInfoArr = newComQualifyInfoArr
            // 是否位不同项，false为相同同项，true为不相同项
            item.different = !(diffSet.size === 1)
            item.isSame = diffSet.size === 1
          }
          this.registerAreaAry = []
          for (const area of this.registerArea) {
            const obj = {
              key: area,
              label: area
            }
            this.registerAreaAry.push(obj)
          }
          this.compareList = COMPARELIST
          this.completeList = JSON.parse(JSON.stringify(COMPARELIST))
          for (const iterator of this.completeList) {
            iterator.check = true
          }
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    // 更多点击事件加载更多资质
    handleClickLoadMore() {
      this.pageNo = this.pageNo + 1
      this.compareSearch.start = (this.pageNo - 1) * this.compareSearch.limit
      compareCompany4Qualify(this.compareSearch).then(data => {
        if (data.ok) {
          const COMPARELIST = data.lst || []
          if (COMPARELIST.length > 0) {
            this.hasMore = true
          } else {
            this.hasMore = false
          }
          for (const item of COMPARELIST) {
            const comqualifyInfoArr = item.comqualifyInfoArr
            this.registerArea.add(item.zhcsd)
            this.orgLst.push({
              key: item.companyID,
              label: item.mingCh
            })
            const diffSet = new Set()
            for (const v of comqualifyInfoArr) {
              if (Object.keys(v).length !== 0) {
                diffSet.add(v.isHave)
              }
            }

            // 根据选择的机构进行排序
            let newComQualifyInfoArr = []
            for (const value of item.comqualifyInfoArr) {
              const strQualifyID = value.dengJ ? `${value.strQualifyCode}@@@${value.dengJ}` : `${value.strQualifyCode}@@@0`
              const index = this.qualificationList.findIndex((cv, index, arr) => cv.strQualifyID === strQualifyID)
              newComQualifyInfoArr[index] = value
            }

            if (newComQualifyInfoArr.length < this.qualificationList.length) {
              const arrLength = this.qualificationList.length - newComQualifyInfoArr.length
              const arr = Array(arrLength).fill({}, 0, arrLength)
              newComQualifyInfoArr = [...newComQualifyInfoArr, ...arr]
            }

            for (let i = 0; i < newComQualifyInfoArr.length; i++) {
              if (!newComQualifyInfoArr[i]) {
                newComQualifyInfoArr[i] = {}
              }
            }

            item.comqualifyInfoArr = newComQualifyInfoArr
            // 是否位不同项，false为相同同项，true为不相同项
            item.different = !(diffSet.size === 1)
            item.isSame = diffSet.size === 1
          }
          // 重置按地区筛选信息
          this.registerAreaAry = []
          for (const area of this.registerArea) {
            const obj = {
              key: area,
              label: area
            }
            this.registerAreaAry.push(obj)
          }
          this.registerAreaSelect = []
          // 重置按机构筛选
          this.orgSelect = []
          // 加载对比列表
          this.compareList = [...this.completeList, ...COMPARELIST]
          this.completeList = [...this.completeList, ...JSON.parse(JSON.stringify(COMPARELIST))]
          for (const iterator of this.completeList) {
            iterator.check = true
          }
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    // 弹出机构筛选框
    showDialogCompany() {
      this.dialogCompanyVisible = true
    },
    // 确定筛选信息
    handleDetermineCompare() {
      if (this.activeName === 'first') {
        if (this.registerAreaSelect.length === 0) {
          this.$message({
            showClose: true,
            message: '最少选择一个地区',
            type: 'warning'
          })
          return
        }
        this.orgSelect = []
        const areaSet = new Set(this.registerAreaSelect)
        const list = this.completeList.filter(data => areaSet.has(data.zhcsd))
        this.compareList = list
      } else {
        if (this.orgSelect.length === 0) {
          this.$message({
            showClose: true,
            message: '最少选择一个对比项',
            type: 'warning'
          })
          return
        }
        this.registerAreaSelect = []
        const orgSet = new Set(this.orgSelect)
        const list = this.completeList.filter(data => orgSet.has(data.companyID))
        this.compareList = list
      }
      this.dialogCompanyVisible = false
    },
    // 添加新的对比项
    addCompareItem() {
      this.qualificationList.push(JSON.parse(JSON.stringify(emptyObj)))
      for (const item of this.compareList) {
        item.comqualifyInfoArr.push({})
      }
    },
    authCheck() {
      const authObj = authenticate('compareCert', this.memberType)
      if (authObj.flag) {
        this.canSee = true
        const params = {
          operateID: 1,
          id: 0
        }
        getInfoTree(params).then(data => {
          if (data.ok) {
            this.qualifyCategoryList = data.lst
          } else {
            this.$notify.error({
              title: '错误',
              message: data.msg
            })
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }

      // 初始化页面
      this.initPage()
    },
    initPage() {
      // this.qualificationList = JSON.parse(JSON.stringify(QUALIFICATIONLIST))
      let List = null
      if (this.canSee) {
        List = sessionStorage.getItem('certCompareList') ? JSON.parse(sessionStorage.getItem('certCompareList')) : []
      }
      if (List) {
        let qualificationList = []
        for (const iterator of List) {
          const obj = {
            strQualifyName: iterator.strQualifyName,
            levelName: iterator.levelName,
            strQualifyID: iterator.strQualifyID
          }
          qualificationList.push(obj)
        }
        if (qualificationList.length < 4) {
          const arrLength = 4 - qualificationList.length
          const arr = Array(arrLength).fill(JSON.parse(JSON.stringify(emptyObj)), 0, arrLength)
          qualificationList = [...qualificationList, ...arr]
        }
        this.qualificationList = JSON.parse(JSON.stringify(qualificationList))
      } else {
        this.qualificationList = JSON.parse(JSON.stringify(QUALIFICATIONLIST))
      }
      this.initCompare()
    },
    // 机构筛选穿梭框格式化
    renderFunc(h, option) {
      return <span title={option.label}> {option.label}</span>
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    window.onresize = () => {
      this.handleScroll()
    }

    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  },
  watch: {
    hideSome(val) {
      if (val) {
        this.showSome = false
      }
    },
    showSome(val) {
      if (val) {
        this.hideSome = false
        this.hightSome = false
      }
    },
    hightSome(val) {
      if (val) {
        this.showSome = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$contentWidth: 1200px;
$widthContainer: 1200px;

// 比对内容
.compare {
  &-container {
    width: $widthContainer;
    margin: 0 auto;
    font-size: 14px;
    padding-bottom: 20px;
    min-height: 450px;
  }

  &-header-fixed {
    position: fixed;
    top: 0;
    z-index: 9;
    .compare-header-tools {
      border-bottom: 1px solid #ddd;
    }
    .compare-header-item {
      border-bottom: 1px solid #ddd;
    }
  }

  &-header {
    display: flex;
    height: 150px;
    &-tools {
      width: 250px;
      align-items: center;
      background: #f2f6fc;
      border-top: 1px solid #dddddd;
      border-left: 1px solid #dddddd;
      border-right: 1px solid #dddddd;
      border-bottom: 1px solid #dddddd;
      text-align: center;
      padding-top: 30px;
      flex-shrink: 0;

      .el-checkbox {
        display: block;
      }

      .screen-info {
        display: inline-block;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        background: #fff;
        border: 1px solid #dcdfe6;
        color: #606266;
        -webkit-appearance: none;
        text-align: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        outline: 0;
        margin: 0;
        -webkit-transition: 0.1s;
        transition: 0.1s;
        font-weight: 500;
        padding: 5px 20px;
        font-size: 14px;
        border-radius: 4px;
        margin-top: 6px;
        background: #398ce2;
        border: 1px solid #398ce2;
        color: #fff;
      }
    }

    &-item {
      width: 240px;
      position: relative;
      padding: 5px 15px;
      border-right: 1px solid #ddd;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #dddddd;
      flex-shrink: 0;
      background: #fff;
      .delete-item {
        position: absolute;
        top: 0px;
        right: 0px;
        font-size: 20px;
        color: #bfbfbf;
        cursor: pointer;
        &:hover {
          color: #fe6811;
        }
      }

      &:nth-last-child(2) {
        .change-item {
          display: none;
        }
      }

      .change-item {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 65px;
        right: -18px;
        text-align: center;
        z-index: 6;
        background: #dcdfe6;
        line-height: 36px;
        border-radius: 50%;
        cursor: pointer;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        font-size: 16px;
        &:hover {
          background: #398ce2;
          color: #fff;
        }
      }

      .product {
        &-title {
          margin-top: 40px;
          color: #333;
          line-height: 20px;
          height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          white-space: nowrap;
        }

        &-model {
          color: #333;
          height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          white-space: nowrap;
          span {
            color: #398ce2;
          }
        }

        &-change {
          padding-top: 10px;
        }

        &-tools {
          margin-top: 10px;
          text-align: center;
          button {
            display: inline-block;
            line-height: 1;
            white-space: nowrap;
            cursor: pointer;
            background: #fff;
            border: 1px solid #dcdfe6;
            color: #606266;
            -webkit-appearance: none;
            text-align: center;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            outline: 0;
            margin: 0;
            -webkit-transition: 0.1s;
            transition: 0.1s;
            font-weight: 500;
            padding: 10px 30px;
            font-size: 14px;
            border-radius: 4px;
            margin-left: 10px;
          }
          .collection {
            background: #fe6811;
            border: 1px solid #fe6811;
            color: #fff;
          }
        }
      }
    }

    &-add {
      width: 40px;
      flex-shrink: 0;
      position: relative;
      button {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 40px;
        font-size: 14px;
        height: 100px;
        color: #fff;
        border: 1px solid;
        background-color: #398ce2;
        border-color: #398ce2;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        cursor: pointer;
      }
    }
  }

  &-content {
    .box {
      &-content {
        display: flex;

        &:first-child {
          .box-title {
            border-top: 1px solid #dddddd;
          }

          .box-body {
            border-top: 1px solid #dddddd;
          }
        }
        &:hover {
          .box-title {
            border-bottom: 1px solid #f43636;
          }
          .box-body {
            border-bottom: 1px solid #f43636;
          }
        }

        &.high {
          .box-body {
            background: #ffffce;
          }
        }

        .placeholder {
          width: 200px;
          flex-shrink: 0;
        }
      }

      &-title {
        width: 250px;
        background: #f2f6fc;
        padding: 15px;
        text-align: right;
        flex-shrink: 0;
        border-bottom: 1px solid #dddddd;
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
      }

      &-body {
        padding: 15px;
        width: 240px;
        flex-shrink: 0;
        border-bottom: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
        vertical-align: middle;
        text-align: center;
        background: #fff;
      }
    }
  }

  &-loadmore {
    width: 1211px;
    height: 45px;
    background: #0174bd;
    color: #fff;
    text-align: center;
    line-height: 45px;
    margin-top: 5px;
    cursor: pointer;
  }
}

.detail-link {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  &:hover {
    color: #398ce2;
  }
}

.company-page {
  text-align: right;
  margin-top: 10px;
}

// 筛选机构中的备注信息
.tips {
  color: #f43636;
}
</style>
